<script setup>
defineProps({
  data: {
    type: Object,
    required: true,
  },
  isExpanded: {
    type: Boolean,
    default: false,
  },
  isOptionsExpanded: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['toggleContent', 'toggleOptions']);

const toggleContent = () => {
  emit('toggleContent');
}

const toggleOptions = () => {
  emit('toggleOptions');
}
</script>

<template>
  <div class="flex bg-white justify-between w-full p-3 rounded-lg mt-3 relative cursor-pointer">
    <div class="flex flex-col gap-2 w-10/12">
      <h2 class="text-lg" @click="toggleContent"><i
          class="fa-solid fa-chevron-down me-3"></i>{{ data.full_name }}</h2>
      <div class="bg-white toggle-content" :class="{ 'hidden': !isExpanded }">
        <div class="flex items-center gap-4 text-sm mb-1">
          <div class="w-1/2">Mobile Number</div>
          <div class="font-medium">({{ data.phone_code }}) {{ data.mobile }}</div>
        </div>
        <div class="flex items-center gap-4 text-sm mb-1" v-if="data.email">
          <div class="w-1/2">Email</div>
          <div class="font-medium">{{ data.email }}</div>
        </div>
        <div class="flex items-center gap-4 text-sm mb-1">
          <div class="w-1/2">Category</div>
          <div class="font-medium">{{ data.category_name }}</div>
        </div>
        <div class="flex items-center gap-4 text-sm mb-1">
          <div class="w-1/2">Source</div>
          <div class="font-medium">{{ data.source_name }}</div>
        </div>
      </div>
    </div>
    <div class="ms-auto mr-2" @click="toggleOptions">
      <i class="fa-solid fa-ellipsis-vertical fa-lg "></i>
    </div>
    <!-- Toggle Content -->
    <div
      class="toggle-content absolute right-0 mt-4 p-3 text-gray-600 bg-white shadow rounded divide-y divide-slate-200 z-50"
      :class="{ 'hidden': !isOptionsExpanded }">
      <NuxtLink :to="localePath({ name: 'account.customers.show', params: { id: data.id } })" class="py-2 block"><i class="fa-solid fa-eye me-2"></i>{{ $t('client.show_details') }}</NuxtLink>
      <NuxtLink :to="localePath({ name: 'account.customers.edit', params: { id: data.id } })" class="py-2 block"> <i class="fa-solid fa-pen me-2"></i>{{ $t('client.edit_customer') }}</NuxtLink>
    </div>
  </div>
</template>